jQuery(document).ready(function(){
  jQuery(".button a").click(function(){
    jQuery(".overlay").fadeToggle(200);
    jQuery(this).toggleClass('btn-open').toggleClass('btn-close');
    jQuery(".site-title").toggleClass('overlay-open');
  });
});
jQuery('.overlay').on('click', function(){
  jQuery(".overlay").fadeToggle(200);   
  jQuery(".button a").toggleClass('btn-open').toggleClass('btn-close');
  open = false;
});


/* SCROLL TO TOP */
jQuery(document).ready(function($){
  
      // browser window scroll (in pixels) after which the "back to top" link is shown
      var offset = 300,
        //browser window scroll (in pixels) after which the "back to top" link opacity is reduced
        offset_opacity = 1500,
        //duration of the top scrolling animation (in ms)
        scroll_top_duration = 700,
        //grab the "back to top" link
        $back_to_top = $('.cd-top');
  
      //hide or show the "back to top" link
      $(window).scroll(function(){
        ( $(this).scrollTop() > offset ) ? $back_to_top.addClass('cd-is-visible') : $back_to_top.removeClass('cd-is-visible cd-fade-out');
        if( $(this).scrollTop() > offset_opacity ) {
          $back_to_top.addClass('cd-fade-out');
        }
      });
  
  //smooth scroll to top
   $back_to_top.on('click', function(event){
     event.preventDefault();
     $('body,html').animate({
     scrollTop: 0 ,
     }, scroll_top_duration
     );
   });

});

var scroll = window.requestAnimationFrame ||
  function(callback){ window.setTimeout(callback, 1000/60)};
  var elementsToShow = document.querySelectorAll('.is-style-animated-image');

function loop() {

  elementsToShow.forEach(function (element) {
    if (isElementInViewport(element)) {
      element.classList.add('animate__animated', 'animate__backInUp');
    }
  });

  scroll(loop);
}

loop();

//Helper function from: http://stackoverflow.com/a/7557433/274826
function isElementInViewport(el) {
  // special bonus for those using jQuery
  if (typeof jQuery === "function" && el instanceof jQuery) {
    el = el[0];
  }
  var rect = el.getBoundingClientRect();
  return (
    (rect.top <= 0
      && rect.bottom >= 0)
    ||
    (rect.bottom >= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.top <= (window.innerHeight || document.documentElement.clientHeight))
    ||
    (rect.top >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight))
  );
}
